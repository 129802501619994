import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

import { TAdHocScreenData, TScreenedEntity } from '@/lib/features/Screening'

const styles = StyleSheet.create({
  reportSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    borderTop: '6 solid #fce700',
    borderBottom: '6 solid #fce700',
    width: '70%',
    marginBottom: 20,
    marginTop: 10,
    paddingVertical: 4
  }
})

type TStatistics = {
  namesFlagged: number
  namesInProgress: number
  namesResolved: number
  reviewsIncomplete: number
  truePositives: number
}

export function calculateStatistics(
  screenEntities: Omit<TScreenedEntity, 'hits'>[]
): TStatistics {
  let namesFlagged = 0
  let namesResolved = 0
  let namesInProgress = 0
  let reviewsIncomplete = 0
  let truePositives = 0

  for (let i = 0; i < screenEntities.length; i++) {
    if (screenEntities[i].totalHits > 0) namesFlagged++

    switch (screenEntities[i].status) {
      case 'resolved':
        namesResolved += 1
        break
      case 'wip':
        namesInProgress += 1
        break
      case 'flagged':
        truePositives += 1
        break
      case 'incomplete':
        reviewsIncomplete += 1
        break
    }
  }

  return {
    namesFlagged,
    namesInProgress,
    namesResolved,
    reviewsIncomplete,
    truePositives
  }
}

const Summary: React.FC<{ screenData: TAdHocScreenData }> = ({
  screenData
}) => {
  const namesScreened = screenData.entities.length
  const {
    namesFlagged,
    namesInProgress,
    namesResolved,
    reviewsIncomplete,
    truePositives
  } = calculateStatistics(screenData.entities)

  return (
    <View fixed style={styles.reportSummary}>
      <View>
        <Text>
          Minimum score:{' '}
          {Math.round(screenData.screen.parameters.scoreThreshold * 100)}%
        </Text>
        <Text>Items screened: {namesScreened}</Text>
        <Text>Items with no matches: {namesScreened - namesFlagged}</Text>
        <Text>Items flagged: {namesFlagged}</Text>
      </View>
      <View>
        <Text>False positives: {namesResolved}</Text>
        <Text>True positives: {truePositives}</Text>
        <Text>Need more information: {namesInProgress}</Text>
        <Text>Review incomplete: {reviewsIncomplete}</Text>
      </View>
    </View>
  )
}

export default Summary
